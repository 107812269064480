import {
  AccessExtension,
  AccessProfile,
  AccessProfileMemberRelationship,
  AdminTask,
  ApplicationSetting,
  AsyncTask,
  AuditLog,
  AutoBenchmarkLlmCall,
  AwardLetter,
  AwardLetterTemplate,
  AwardLetterTemplateSection,
  BenchmarkResult,
  BenchmarkingWorkflow,
  Benefit,
  BenefitAssignment,
  BenefitsPackage,
  CartaEquityGrant,
  Company,
  CompanyOnboardingConfig,
  CompensationApproval,
  CompensationApprovalLevel,
  CompensationEvent,
  CustomField,
  DataImport,
  DataImportApiLog,
  EmailAddress,
  ExchangeRate,
  Family,
  FamilyGroup,
  FieldTablePlacement,
  FileUpload,
  GrantType,
  Integration,
  Job,
  KeyResult,
  LLMPrompt,
  Level,
  LlmConversation,
  LlmConversationMessage,
  LogicGraph,
  LogicRun,
  ManagerHierarchy,
  Objective,
  Partner,
  PerformanceAnswer,
  PerformanceCycle,
  PerformanceQuestion,
  PerformanceRating,
  Presence,
  Proposal,
  ProposerBudget,
  Range,
  RangeUpload,
  RangesWithTaxonomy,
  Review,
  ReviewSetupConfig,
  ReviewTableFilterState,
  StockPrice,
  SystemFieldOverride,
  TableSettings,
  Track,
  User,
  UserTableState,
  Zone,
} from "prisma/cm/client";

export type ModelManifest = {
  AccessExtension: AccessExtension;
  AccessProfile: AccessProfile;
  AccessProfileMemberRelationship: AccessProfileMemberRelationship;
  AdminTask: AdminTask;
  ApplicationSetting: ApplicationSetting;
  AsyncTask: AsyncTask;
  AuditLog: AuditLog;
  AutoBenchmarkLlmCall: AutoBenchmarkLlmCall;
  AwardLetter: AwardLetter;
  AwardLetterTemplate: AwardLetterTemplate;
  AwardLetterTemplateSection: AwardLetterTemplateSection;
  BenchmarkResult: BenchmarkResult;
  BenchmarkingWorkflow: BenchmarkingWorkflow;
  Benefit: Benefit;
  BenefitAssignment: BenefitAssignment;
  BenefitsPackage: BenefitsPackage;
  CartaEquityGrant: CartaEquityGrant;
  Company: Company;
  CompanyOnboardingConfig: CompanyOnboardingConfig;
  CompensationApproval: CompensationApproval;
  CompensationApprovalLevel: CompensationApprovalLevel;
  CompensationEvent: CompensationEvent;
  CustomField: CustomField;
  DataImport: DataImport;
  DataImportApiLog: DataImportApiLog;
  EmailAddress: EmailAddress;
  ExchangeRate: ExchangeRate;
  Family: Family;
  FamilyGroup: FamilyGroup;
  FieldTablePlacement: FieldTablePlacement;
  FileUpload: FileUpload;
  GrantType: GrantType;
  Integration: Integration;
  Job: Job;
  KeyResult: KeyResult;
  LLMPrompt: LLMPrompt;
  Level: Level;
  LlmConversation: LlmConversation;
  LlmConversationMessage: LlmConversationMessage;
  LogicGraph: LogicGraph;
  LogicRun: LogicRun;
  ManagerHierarchy: ManagerHierarchy;
  Objective: Objective;
  Partner: Partner;
  PerformanceAnswer: PerformanceAnswer;
  PerformanceCycle: PerformanceCycle;
  PerformanceQuestion: PerformanceQuestion;
  PerformanceRating: PerformanceRating;
  Presence: Presence;
  Proposal: Proposal;
  ProposerBudget: ProposerBudget;
  Range: Range;
  RangeUpload: RangeUpload;
  RangesWithTaxonomy: RangesWithTaxonomy;
  Review: Review;
  ReviewSetupConfig: ReviewSetupConfig;
  ReviewTableFilterState: ReviewTableFilterState;
  StockPrice: StockPrice;
  SystemFieldOverride: SystemFieldOverride;
  TableSettings: TableSettings;
  Track: Track;
  User: User;
  UserTableState: UserTableState;
  Zone: Zone;
};

export const stateGraphConfig = {
  AccessExtension: {
    objectName: "AccessExtension",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      createdBy: {
        targetObjectName: "User",
        sourceIdKey: "createdById",
        targetIdKey: "id",
      },
      range: {
        targetObjectName: "Range",
        sourceIdKey: "rangeId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      rangeId: true,
      createdAt: true,
      createdById: true,
      meta: true,
      userId: true,
      company: true,
      createdBy: true,
      range: true,
      user: true,
    },
  },
  AccessProfile: {
    objectName: "AccessProfile",
    relations: {
      accessProfileMemberRelationships: {
        hasMany: true,
        targetObjectName: "AccessProfileMemberRelationship",
        sourceIdKey: "id",
        targetIdKey: "accessProfileId",
      },
      adminAccessProfileForReviews: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "adminAccessProfileId",
      },
      approverAccessProfileForReviews: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "approverAccessProfileId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      defaultAccessProfileForCompany: { targetObjectName: "Company" },
      defaultReviewAdminAccessProfileForCompany: {
        targetObjectName: "Company",
      },
      defaultReviewApproverAccessProfileForCompany: {
        targetObjectName: "Company",
      },
      defaultReviewPartnerAccessProfileForCompany: {
        targetObjectName: "Company",
      },
      defaultReviewProposerAccessProfileForCompany: {
        targetObjectName: "Company",
      },
      partnerAccessProfileForReviews: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "partnerAccessProfileId",
      },
      proposerAccessProfileForReviews: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "proposerAccessProfileId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      name: true,
      companyId: true,
      createdAt: true,
      updatedAt: true,
      policyData: true,
      description: true,
      autoMembershipRules: true,
      accessProfileMemberRelationships: true,
      company: true,
      defaultAccessProfileForCompany: true,
      defaultReviewAdminAccessProfileForCompany: true,
      defaultReviewApproverAccessProfileForCompany: true,
      defaultReviewPartnerAccessProfileForCompany: true,
      defaultReviewProposerAccessProfileForCompany: true,
      adminAccessProfileForReviews: true,
      approverAccessProfileForReviews: true,
      partnerAccessProfileForReviews: true,
      proposerAccessProfileForReviews: true,
    },
  },
  AccessProfileMemberRelationship: {
    objectName: "AccessProfileMemberRelationship",
    relations: {
      Company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      accessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "accessProfileId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      accessProfileId: true,
      userId: true,
      createdAt: true,
      updatedAt: true,
      companyId: true,
      id: true,
      accessProfile: true,
      Company: true,
      user: true,
    },
  },
  AdminTask: {
    objectName: "AdminTask",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      email: {
        targetObjectName: "EmailAddress",
        sourceIdKey: "emailId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      type: true,
      status: true,
      metadata: true,
      emailId: true,
      company: true,
      email: true,
    },
  },
  ApplicationSetting: {
    objectName: "ApplicationSetting",
    relations: {},
    hasField: { explicitKind: true, id: true, key: true, value: true },
  },
  AsyncTask: {
    objectName: "AsyncTask",
    relations: {},
    hasField: {
      explicitKind: true,
      id: true,
      type: true,
      status: true,
      data: true,
      logs: true,
      createdAt: true,
      completedAt: true,
    },
  },
  AuditLog: {
    objectName: "AuditLog",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      modelType: true,
      modelInstance: true,
      versionActiveEnd: true,
      versionBaseRecordId: true,
      versionCreatedBy: true,
      modelDiff: true,
      changeSummary: true,
      companyId: true,
      company: true,
    },
  },
  AutoBenchmarkLlmCall: {
    objectName: "AutoBenchmarkLlmCall",
    relations: {},
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      workflowId: true,
      model: true,
      input: true,
      output: true,
      extra: true,
      createdAt: true,
      updatedAt: true,
    },
  },
  AwardLetter: {
    objectName: "AwardLetter",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      reviewId: true,
      userId: true,
      templateId: true,
      status: true,
      s3Url: true,
      createdAt: true,
      updatedAt: true,
      companyId: true,
      company: true,
    },
  },
  AwardLetterTemplate: {
    objectName: "AwardLetterTemplate",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
      sections: {
        hasMany: true,
        targetObjectName: "AwardLetterTemplateSection",
        sourceIdKey: "id",
        targetIdKey: "templateId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      reviewId: true,
      intro: true,
      preface: true,
      postface: true,
      createdAt: true,
      updatedAt: true,
      sections: true,
      company: true,
      review: true,
    },
  },
  AwardLetterTemplateSection: {
    objectName: "AwardLetterTemplateSection",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      template: {
        targetObjectName: "AwardLetterTemplate",
        sourceIdKey: "templateId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      templateId: true,
      type: true,
      typeSortOrder: true,
      subType: true,
      subTypeSortOrder: true,
      header: true,
      body: true,
      createdAt: true,
      updatedAt: true,
      companyId: true,
      id: true,
      company: true,
      template: true,
    },
  },
  BenchmarkResult: {
    objectName: "BenchmarkResult",
    relations: {
      benchmarkWorkflow: {
        targetObjectName: "BenchmarkingWorkflow",
        sourceIdKey: "benchmarkingWorkflowId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      employee: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      userId: true,
      benchmarkingWorkflowId: true,
      benchmarkCandidate: true,
      benchmarkAssignment: true,
      resolvedBenchmarkData: true,
      approved: true,
      employeeSalaryData: true,
      geoLadder: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      employee: true,
      benchmarkWorkflow: true,
    },
  },
  BenchmarkingWorkflow: {
    objectName: "BenchmarkingWorkflow",
    relations: {
      BenchmarkResult: {
        hasMany: true,
        targetObjectName: "BenchmarkResult",
        sourceIdKey: "id",
        targetIdKey: "benchmarkingWorkflowId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      requestOptions: true,
      status: true,
      createdAt: true,
      updatedAt: true,
      BenchmarkResult: true,
    },
  },
  Benefit: {
    objectName: "Benefit",
    relations: {
      benefitAssignments: {
        hasMany: true,
        targetObjectName: "BenefitAssignment",
        sourceIdKey: "id",
        targetIdKey: "benefitId",
      },
      benefitsPackage: {
        targetObjectName: "BenefitsPackage",
        sourceIdKey: "benefitsPackageId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      name: true,
      adminLabel: true,
      amountType: true,
      amountCents: true,
      amountCurrencyCode: true,
      amountPercent: true,
      createdAt: true,
      updatedAt: true,
      amountIsMax: true,
      excludeFromTotal: true,
      benefitsPackageId: true,
      source: true,
      amountFrequency: true,
      benefitsPackage: true,
      company: true,
      benefitAssignments: true,
    },
  },
  BenefitAssignment: {
    objectName: "BenefitAssignment",
    relations: {
      benefit: {
        targetObjectName: "Benefit",
        sourceIdKey: "benefitId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      benefitId: true,
      companyId: true,
      userId: true,
      benefit: true,
      company: true,
      user: true,
    },
  },
  BenefitsPackage: {
    objectName: "BenefitsPackage",
    relations: {
      benefits: {
        hasMany: true,
        targetObjectName: "Benefit",
        sourceIdKey: "id",
        targetIdKey: "benefitsPackageId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      name: true,
      adminLabel: true,
      createdAt: true,
      updatedAt: true,
      description: true,
      benefits: true,
      company: true,
    },
  },
  CartaEquityGrant: {
    objectName: "CartaEquityGrant",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      userId: true,
      securityId: true,
      awardType: true,
      exercisePrice: true,
      sharesIssued: true,
      sharesExercised: true,
      sharesCanceled: true,
      marketPriceAtIssue: true,
      vestingSchedule: true,
      vestingStartDate: true,
      vestingEndDate: true,
      canceledDate: true,
      issueDate: true,
      companyId: true,
      cumulativeVested: true,
      company: true,
      user: true,
      createdAt: true,
      updatedAt: true,
    },
  },
  Company: {
    objectName: "Company",
    relations: {
      AccessProfile: {
        hasMany: true,
        targetObjectName: "AccessProfile",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      AuditLog: {
        hasMany: true,
        targetObjectName: "AuditLog",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      AwardLetter: {
        hasMany: true,
        targetObjectName: "AwardLetter",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      AwardLetterTemplateSection: {
        hasMany: true,
        targetObjectName: "AwardLetterTemplateSection",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      BenchmarkResult: {
        hasMany: true,
        targetObjectName: "BenchmarkResult",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      CartaEquityGrant: {
        hasMany: true,
        targetObjectName: "CartaEquityGrant",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      CompensationApproval: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      CompensationApprovalLevel: {
        hasMany: true,
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      LlmConversation: {
        hasMany: true,
        targetObjectName: "LlmConversation",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      LlmConversationMessage: {
        hasMany: true,
        targetObjectName: "LlmConversationMessage",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      LogicGraph: {
        hasMany: true,
        targetObjectName: "LogicGraph",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      Proposal: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      ProposerBudget: {
        hasMany: true,
        targetObjectName: "ProposerBudget",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      ReviewSetupConfig: {
        hasMany: true,
        targetObjectName: "ReviewSetupConfig",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      TableSettings: {
        hasMany: true,
        targetObjectName: "TableSettings",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      UserTableState: {
        hasMany: true,
        targetObjectName: "UserTableState",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      accessExtensions: {
        hasMany: true,
        targetObjectName: "AccessExtension",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      accessProfileMemberRelationships: {
        hasMany: true,
        targetObjectName: "AccessProfileMemberRelationship",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      adminTasks: {
        hasMany: true,
        targetObjectName: "AdminTask",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      awardLetterTemplate: {
        hasMany: true,
        targetObjectName: "AwardLetterTemplate",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      benefitAssignments: {
        hasMany: true,
        targetObjectName: "BenefitAssignment",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      benefits: {
        hasMany: true,
        targetObjectName: "Benefit",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      benefitsPackages: {
        hasMany: true,
        targetObjectName: "BenefitsPackage",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      compensationEvents: {
        hasMany: true,
        targetObjectName: "CompensationEvent",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      customFields: {
        hasMany: true,
        targetObjectName: "CustomField",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      dataImportApiLogs: {
        hasMany: true,
        targetObjectName: "DataImportApiLog",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      dataImports: {
        hasMany: true,
        targetObjectName: "DataImport",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      defaultAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "defaultAccessProfileId",
        targetIdKey: "id",
      },
      defaultReviewAdminAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "defaultReviewAdminAccessProfileId",
        targetIdKey: "id",
      },
      defaultReviewApproverAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "defaultReviewApproverAccessProfileId",
        targetIdKey: "id",
      },
      defaultReviewPartnerAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "defaultReviewPartnerAccessProfileId",
        targetIdKey: "id",
      },
      defaultReviewProposerAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "defaultReviewProposerAccessProfileId",
        targetIdKey: "id",
      },
      emails: {
        hasMany: true,
        targetObjectName: "EmailAddress",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      families: {
        hasMany: true,
        targetObjectName: "Family",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      familyGroups: {
        hasMany: true,
        targetObjectName: "FamilyGroup",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      fieldTablePlacements: {
        hasMany: true,
        targetObjectName: "FieldTablePlacement",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      fileUploads: {
        hasMany: true,
        targetObjectName: "FileUpload",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      grantTypes: {
        hasMany: true,
        targetObjectName: "GrantType",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      integrations: {
        hasMany: true,
        targetObjectName: "Integration",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      jobs: {
        hasMany: true,
        targetObjectName: "Job",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      keyResults: {
        hasMany: true,
        targetObjectName: "KeyResult",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      levels: {
        hasMany: true,
        targetObjectName: "Level",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      logicRuns: {
        hasMany: true,
        targetObjectName: "LogicRun",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      objectives: {
        hasMany: true,
        targetObjectName: "Objective",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      onboardingConfig: {
        targetObjectName: "CompanyOnboardingConfig",
        sourceIdKey: "onboardingConfigId",
        targetIdKey: "id",
      },
      performanceAnswers: {
        hasMany: true,
        targetObjectName: "PerformanceAnswer",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      performanceCycles: {
        hasMany: true,
        targetObjectName: "PerformanceCycle",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      performanceQuestions: {
        hasMany: true,
        targetObjectName: "PerformanceQuestion",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      performanceRatings: {
        hasMany: true,
        targetObjectName: "PerformanceRating",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      rangeUploads: {
        hasMany: true,
        targetObjectName: "RangeUpload",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      ranges: {
        hasMany: true,
        targetObjectName: "Range",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      reviewTableFilterStates: {
        hasMany: true,
        targetObjectName: "ReviewTableFilterState",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      reviews: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      systemFieldOverrides: {
        hasMany: true,
        targetObjectName: "SystemFieldOverride",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      tracks: {
        hasMany: true,
        targetObjectName: "Track",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      users: {
        hasMany: true,
        targetObjectName: "User",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
      zones: {
        hasMany: true,
        targetObjectName: "Zone",
        sourceIdKey: "id",
        targetIdKey: "companyId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      name: true,
      legalName: true,
      ein: true,
      rangeSource: true,
      enableTaxFields: true,
      taxContributionPlanUrl: true,
      rangeDimensions: true,
      showLevelInAwardLetter: true,
      logo: true,
      weeklyWorkingHours: true,
      accessPolicy: true,
      isOnboarding: true,
      isTestCompany: true,
      payRangeType: true,
      employeeDashboardSettings: true,
      enableEmployeeDashboard: true,
      enableEmployeeDashboardAccess: true,
      oktaCompanySlug: true,
      dateFormat: true,
      defaultCurrency: true,
      locale: true,
      localeSettings: true,
      payPointSettings: true,
      defaultAccessProfileId: true,
      defaultReviewAdminAccessProfileId: true,
      defaultReviewApproverAccessProfileId: true,
      defaultReviewPartnerAccessProfileId: true,
      defaultReviewProposerAccessProfileId: true,
      oktaClientId: true,
      oktaClientSecret: true,
      oktaHostname: true,
      enableAdminBenchmarking: true,
      enableAdminMercerBenchmarking: true,
      enableAdminComptryxBenchmarking: true,
      enableAwardLetterCoaching: true,
      enableOnboarding: true,
      enableAdminBenchmarkMapping: true,
      isTrialSandbox: true,
      showCompensationHistory: true,
      enabledSourcesForCompensationHistory: true,
      microsoftEntraTenantId: true,
      createdAt: true,
      updatedAt: true,
      partnerId: true,
      partnerGovernanceExempt: true,
      cycleCadence: true,
      glossaryTerms: true,
      suppressEmployeeEmails: true,
      stockSymbol: true,
      stockExchange: true,
      expectedNextCycleDate: true,
      churnDate: true,
      contractStartDate: true,
      includeBonusTargetInOTECalculation: true,
      apiKey: true,
      autoCalculateVariableTargetIncrease: true,
      onboardingConfigId: true,
      appModules: true,
      benchmarkPercentileSettings: true,
      accessExtensions: true,
      accessProfileMemberRelationships: true,
      AccessProfile: true,
      adminTasks: true,
      AuditLog: true,
      AwardLetterTemplateSection: true,
      awardLetterTemplate: true,
      AwardLetter: true,
      benefits: true,
      benefitAssignments: true,
      benefitsPackages: true,
      CartaEquityGrant: true,
      defaultAccessProfile: true,
      defaultReviewAdminAccessProfile: true,
      defaultReviewApproverAccessProfile: true,
      defaultReviewPartnerAccessProfile: true,
      defaultReviewProposerAccessProfile: true,
      CompensationApprovalLevel: true,
      CompensationApproval: true,
      compensationEvents: true,
      customFields: true,
      dataImports: true,
      emails: true,
      families: true,
      familyGroups: true,
      fieldTablePlacements: true,
      fileUploads: true,
      integrations: true,
      jobs: true,
      levels: true,
      logicRuns: true,
      performanceCycles: true,
      performanceRatings: true,
      Proposal: true,
      ProposerBudget: true,
      ranges: true,
      reviewTableFilterStates: true,
      reviews: true,
      systemFieldOverrides: true,
      TableSettings: true,
      tracks: true,
      UserTableState: true,
      users: true,
      zones: true,
      LogicGraph: true,
      grantTypes: true,
      ReviewSetupConfig: true,
      objectives: true,
      keyResults: true,
      performanceQuestions: true,
      performanceAnswers: true,
      LlmConversation: true,
      LlmConversationMessage: true,
      onboardingConfig: true,
      dataImportApiLogs: true,
      BenchmarkResult: true,
      rangeUploads: true,
    },
  },
  CompanyOnboardingConfig: {
    objectName: "CompanyOnboardingConfig",
    relations: {
      Company: {
        hasMany: true,
        targetObjectName: "Company",
        sourceIdKey: "id",
        targetIdKey: "onboardingConfigId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      completedAt: true,
      companyId: true,
      Company: true,
      createdAt: true,
      updatedAt: true,
      viewedAnalyticsAt: true,
      viewedAccessProfilesAt: true,
      viewedCustomFieldsAt: true,
      postponedTotalRewardsSetupAt: true,
      postponedPerformanceRatingsSetupAt: true,
    },
  },
  CompensationApproval: {
    objectName: "CompensationApproval",
    relations: {
      approvalLevel: {
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "reviewId",
        targetIdKey: "reviewId",
      },
      approver: {
        targetObjectName: "User",
        sourceIdKey: "approverId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      explicitApprovalLevel: {
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "explicitApprovalLevelId",
        targetIdKey: "id",
      },
      proposer: {
        targetObjectName: "User",
        sourceIdKey: "proposerId",
        targetIdKey: "id",
      },
      proposerBudget: {
        targetObjectName: "ProposerBudget",
        sourceIdKey: "proposerBudgetId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      reviewId: true,
      proposerId: true,
      approverId: true,
      isDirect: true,
      status: true,
      approvedAt: true,
      approvedByName: true,
      approvedById: true,
      rejectionReason: true,
      rejectedAt: true,
      rejectedByName: true,
      rejectedById: true,
      id: true,
      proposerBudgetId: true,
      companyId: true,
      explicitApprovalLevelId: true,
      createdAt: true,
      updatedAt: true,
      proposer: true,
      approver: true,
      company: true,
      explicitApprovalLevel: true,
      proposerBudget: true,
      approvalLevel: true,
      review: true,
    },
  },
  CompensationApprovalLevel: {
    objectName: "CompensationApprovalLevel",
    relations: {
      approvals: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "reviewId",
        targetIdKey: "reviewId",
      },
      approver: {
        targetObjectName: "User",
        sourceIdKey: "approverId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      explicitApprovals: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "id",
        targetIdKey: "explicitApprovalLevelId",
      },
      explicitParent: {
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "explicitParentId",
        targetIdKey: "id",
      },
      explicitSubLevels: {
        hasMany: true,
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "id",
        targetIdKey: "explicitParentId",
      },
      parent: {
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "reviewId",
        targetIdKey: "reviewId",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
      subLevels: {
        hasMany: true,
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "reviewId",
        targetIdKey: "reviewId",
      },
    },
    hasField: {
      explicitKind: true,
      reviewId: true,
      approverId: true,
      parentId: true,
      depth: true,
      id: true,
      companyId: true,
      explicitParentId: true,
      bonusBudget: true,
      equityBudget: true,
      raiseBudget: true,
      computedAggregates: true,
      raiseBudgetInputMode: true,
      approver: true,
      company: true,
      explicitParent: true,
      explicitSubLevels: true,
      review: true,
      parent: true,
      subLevels: true,
      explicitApprovals: true,
      approvals: true,
    },
  },
  CompensationEvent: {
    objectName: "CompensationEvent",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      source: true,
      eventType: true,
      eventDate: true,
      userId: true,
      metadata: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      user: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
    },
  },
  CustomField: {
    objectName: "CustomField",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      logicGraphs: {
        hasMany: true,
        targetObjectName: "LogicGraph",
        sourceIdKey: "id",
        targetIdKey: "customFieldId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      label: true,
      description: true,
      model: true,
      valueType: true,
      isEditable: true,
      options: true,
      sourceType: true,
      sourceSubType: true,
      createAwardLetterTemplateSection: true,
      enableSuggestions: true,
      isRequired: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      logicGraphs: true,
    },
  },
  DataImport: {
    objectName: "DataImport",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      dataImportApiLogs: {
        hasMany: true,
        targetObjectName: "DataImportApiLog",
        sourceIdKey: "id",
        targetIdKey: "dataImportId",
      },
      integration: {
        targetObjectName: "Integration",
        sourceIdKey: "integrationId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      status: true,
      changeFileS3Url: true,
      rawDataFileS3Url: true,
      externalJobId: true,
      createdAt: true,
      updatedAt: true,
      metrics: true,
      source: true,
      type: true,
      company: true,
      integrationId: true,
      integration: true,
      dataImportApiLogs: true,
    },
  },
  DataImportApiLog: {
    objectName: "DataImportApiLog",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      dataImport: {
        targetObjectName: "DataImport",
        sourceIdKey: "dataImportId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      dataImportId: true,
      url: true,
      s3FileUrl: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      dataImport: true,
    },
  },
  EmailAddress: {
    objectName: "EmailAddress",
    relations: {
      adminTask: { targetObjectName: "AdminTask" },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      userId: true,
      email: true,
      isPrimary: true,
      companyId: true,
      snapshotCycleId: true,
      versionBaseRecordId: true,
      adminTask: true,
      company: true,
      user: true,
    },
  },
  ExchangeRate: {
    objectName: "ExchangeRate",
    relations: {},
    hasField: {
      explicitKind: true,
      id: true,
      from: true,
      to: true,
      rate: true,
      date: true,
    },
  },
  Family: {
    objectName: "Family",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      familyGroup: {
        targetObjectName: "FamilyGroup",
        sourceIdKey: "familyGroupId",
        targetIdKey: "id",
      },
      jobs: {
        hasMany: true,
        targetObjectName: "Job",
        sourceIdKey: "id",
        targetIdKey: "familyId",
      },
      versionBaseRecord: {
        targetObjectName: "Family",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Family",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      name: true,
      id: true,
      companyId: true,
      extra: true,
      description: true,
      versionBaseRecordId: true,
      familyGroupId: true,
      snapshotCycleId: true,
      company: true,
      familyGroup: true,
      versionBaseRecord: true,
      versionHistory: true,
      jobs: true,
    },
  },
  FamilyGroup: {
    objectName: "FamilyGroup",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      families: {
        hasMany: true,
        targetObjectName: "Family",
        sourceIdKey: "id",
        targetIdKey: "familyGroupId",
      },
      versionBaseRecord: {
        targetObjectName: "FamilyGroup",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "FamilyGroup",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      name: true,
      id: true,
      companyId: true,
      extra: true,
      description: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      families: true,
      company: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
  FieldTablePlacement: {
    objectName: "FieldTablePlacement",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      reviewId: true,
      companyId: true,
      promotions: true,
      salary: true,
      bonus: true,
      equity: true,
      summary: true,
      overview: true,
      awardLetters: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      review: true,
    },
  },
  FileUpload: {
    objectName: "FileUpload",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      review: { targetObjectName: "Review" },
      uploadedBy: {
        targetObjectName: "User",
        sourceIdKey: "uploadedById",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      createdAt: true,
      updatedAt: true,
      uploadType: true,
      uploaderType: true,
      uploadedBy: true,
      uploadedById: true,
      type: true,
      bucket: true,
      key: true,
      name: true,
      company: true,
      review: true,
    },
  },
  GrantType: {
    objectName: "GrantType",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      name: true,
      companyId: true,
      vestingSchedule: true,
      vestingPeriod: true,
      vestingCliff: true,
      company: true,
      createdAt: true,
      updatedAt: true,
    },
  },
  Integration: {
    objectName: "Integration",
    relations: {
      DataImport: {
        hasMany: true,
        targetObjectName: "DataImport",
        sourceIdKey: "id",
        targetIdKey: "integrationId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      displayName: true,
      externalCompanyId: true,
      integrationType: true,
      provider: true,
      createdAt: true,
      createdBy: true,
      autoSync: true,
      autoSyncOrder: true,
      dataType: true,
      credentials: true,
      settings: true,
      lastRefreshTime: true,
      lastSyncTime: true,
      company: true,
      DataImport: true,
    },
  },
  Job: {
    objectName: "Job",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      family: {
        targetObjectName: "Family",
        sourceIdKey: "familyId",
        targetIdKey: "id",
      },
      level: {
        targetObjectName: "Level",
        sourceIdKey: "levelId",
        targetIdKey: "id",
      },
      ranges: {
        hasMany: true,
        targetObjectName: "Range",
        sourceIdKey: "id",
        targetIdKey: "jobId",
      },
      versionBaseRecord: {
        targetObjectName: "Job",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Job",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      name: true,
      code: true,
      id: true,
      companyId: true,
      extra: true,
      familyId: true,
      levelId: true,
      description: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      company: true,
      family: true,
      level: true,
      versionBaseRecord: true,
      versionHistory: true,
      ranges: true,
    },
  },
  KeyResult: {
    objectName: "KeyResult",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      objective: {
        targetObjectName: "Objective",
        sourceIdKey: "objectiveId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
      versionBaseRecord: {
        targetObjectName: "KeyResult",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "KeyResult",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      companyId: true,
      objectiveId: true,
      userId: true,
      integrationUserId: true,
      integrationKeyResultId: true,
      description: true,
      type: true,
      value: true,
      company: true,
      user: true,
      objective: true,
      versionBaseRecord: true,
      versionHistory: true,
      createdAt: true,
      updatedAt: true,
    },
  },
  LLMPrompt: {
    objectName: "LLMPrompt",
    relations: {
      LlmConversation: {
        hasMany: true,
        targetObjectName: "LlmConversation",
        sourceIdKey: "id",
        targetIdKey: "lLMPromptId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      key: true,
      prompt: true,
      createdAt: true,
      updatedAt: true,
      LlmConversation: true,
    },
  },
  Level: {
    objectName: "Level",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      jobs: {
        hasMany: true,
        targetObjectName: "Job",
        sourceIdKey: "id",
        targetIdKey: "levelId",
      },
      track: {
        targetObjectName: "Track",
        sourceIdKey: "trackId",
        targetIdKey: "id",
      },
      versionBaseRecord: {
        targetObjectName: "Level",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Level",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      name: true,
      id: true,
      companyId: true,
      extra: true,
      seniority: true,
      trackId: true,
      description: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      jobs: true,
      company: true,
      track: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
  LlmConversation: {
    objectName: "LlmConversation",
    relations: {
      LLMPrompt: {
        targetObjectName: "LLMPrompt",
        sourceIdKey: "lLMPromptId",
        targetIdKey: "id",
      },
      User: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      messages: {
        hasMany: true,
        targetObjectName: "LlmConversationMessage",
        sourceIdKey: "id",
        targetIdKey: "llmConversationId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      userId: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      messages: true,
      User: true,
      LLMPrompt: true,
      lLMPromptId: true,
    },
  },
  LlmConversationMessage: {
    objectName: "LlmConversationMessage",
    relations: {
      User: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      conversation: {
        targetObjectName: "LlmConversation",
        sourceIdKey: "llmConversationId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      userId: true,
      message: true,
      llmConversationId: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      conversation: true,
      User: true,
    },
  },
  LogicGraph: {
    objectName: "LogicGraph",
    relations: {
      CustomField: {
        targetObjectName: "CustomField",
        sourceIdKey: "customFieldId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      reviewId: true,
      logicType: true,
      companyId: true,
      nodeMap: true,
      editorSettings: true,
      createdAt: true,
      updatedAt: true,
      editedThroughFlumeEditor: true,
      company: true,
      review: true,
      customFieldId: true,
      CustomField: true,
      upstreamCustomFieldIds: true,
      upstreamReviewSuggestions: true,
    },
  },
  LogicRun: {
    objectName: "LogicRun",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      type: true,
      status: true,
      error: true,
      metrics: true,
      logs: true,
      extra: true,
      opts: true,
      createdAt: true,
      updatedAt: true,
      company: true,
    },
  },
  ManagerHierarchy: {
    objectName: "ManagerHierarchy",
    relations: {},
    hasField: {
      id: true,
      name: true,
      managerId: true,
      userRangeId: true,
      companyId: true,
      managerListNames: true,
      managerListIds: true,
      range_company_id: true,
      rangeId: true,
      jobId: true,
      jobName: true,
      levelId: true,
      levelName: true,
      levelSeniority: true,
      levelSeniorityPlusOne: true,
      familyId: true,
      familyName: true,
      zoneId: true,
      zoneName: true,
      trackId: true,
      trackName: true,
    },
  },
  Objective: {
    objectName: "Objective",
    relations: {
      childObjectives: {
        hasMany: true,
        targetObjectName: "Objective",
        sourceIdKey: "id",
        targetIdKey: "parentObjectiveId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      keyResults: {
        hasMany: true,
        targetObjectName: "KeyResult",
        sourceIdKey: "id",
        targetIdKey: "objectiveId",
      },
      parentObjective: {
        targetObjectName: "Objective",
        sourceIdKey: "parentObjectiveId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
      versionBaseRecord: {
        targetObjectName: "Objective",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Objective",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      companyId: true,
      userId: true,
      parentObjectiveId: true,
      integrationObjectiveId: true,
      integrationUserId: true,
      integrationParentObjectiveId: true,
      description: true,
      scope: true,
      startDate: true,
      endDate: true,
      status: true,
      percentage: true,
      company: true,
      user: true,
      parentObjective: true,
      childObjectives: true,
      keyResults: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
  Partner: {
    objectName: "Partner",
    relations: {
      adminUsers: {
        hasMany: true,
        targetObjectName: "User",
        sourceIdKey: "id",
        targetIdKey: "governedPartnerId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      createdAt: true,
      updatedAt: true,
      adminUsers: true,
    },
  },
  PerformanceAnswer: {
    objectName: "PerformanceAnswer",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      question: {
        targetObjectName: "PerformanceQuestion",
        sourceIdKey: "questionId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      snapshotCycleId: true,
      questionId: true,
      userId: true,
      integrationQuestionId: true,
      integrationAnswerId: true,
      integrationAuthorId: true,
      integrationParticipantId: true,
      createdAt: true,
      updatedAt: true,
      status: true,
      value: true,
      metrics_value: true,
      company: true,
      question: true,
      user: true,
    },
  },
  PerformanceCycle: {
    objectName: "PerformanceCycle",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      performanceRatings: {
        hasMany: true,
        targetObjectName: "PerformanceRating",
        sourceIdKey: "id",
        targetIdKey: "performanceCycleId",
      },
      versionBaseRecord: {
        targetObjectName: "PerformanceCycle",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "PerformanceCycle",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      provider: true,
      integrationCycleId: true,
      name: true,
      snapshotCycleId: true,
      createdAt: true,
      updatedAt: true,
      status: true,
      versionBaseRecordId: true,
      company: true,
      performanceRatings: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
  PerformanceQuestion: {
    objectName: "PerformanceQuestion",
    relations: {
      PerformanceAnswer: {
        hasMany: true,
        targetObjectName: "PerformanceAnswer",
        sourceIdKey: "id",
        targetIdKey: "questionId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      snapshotCycleId: true,
      reviewId: true,
      integrationQuestionId: true,
      title: true,
      overrideTitle: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      PerformanceAnswer: true,
      review: true,
    },
  },
  PerformanceRating: {
    objectName: "PerformanceRating",
    relations: {
      Company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      cycle: {
        targetObjectName: "PerformanceCycle",
        sourceIdKey: "performanceCycleId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
      versionBaseRecord: {
        targetObjectName: "PerformanceRating",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "PerformanceRating",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      performanceCycleId: true,
      userId: true,
      integrationRatingId: true,
      integrationUserId: true,
      integrationManagerId: true,
      title: true,
      value: true,
      score: true,
      createdAt: true,
      updatedAt: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      Company: true,
      cycle: true,
      user: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
  Presence: {
    objectName: "Presence",
    relations: {
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      userId: true,
      companyId: true,
      proposalId: true,
      columnId: true,
      rowId: true,
      color: true,
      createdAt: true,
      updatedAt: true,
      user: true,
    },
  },
  Proposal: {
    objectName: "Proposal",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      managerLiveRecord: {
        targetObjectName: "User",
        sourceIdKey: "managerId",
        targetIdKey: "id",
      },
      owner: {
        targetObjectName: "User",
        sourceIdKey: "ownerId",
        targetIdKey: "id",
      },
      proposer: {
        targetObjectName: "User",
        sourceIdKey: "proposerId",
        targetIdKey: "id",
      },
      proposerBudget: {
        targetObjectName: "ProposerBudget",
        sourceIdKey: "proposerBudgetId",
        targetIdKey: "id",
      },
      range: {
        targetObjectName: "Range",
        sourceIdKey: "rangeId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      reviewId: true,
      proposerId: true,
      suggestedRaise: true,
      suggestedBonus: true,
      raise: true,
      raisePercent: true,
      raiseInputMode: true,
      salaryIncomeFrequency: true,
      variableTarget: true,
      adjustVariableTarget: true,
      bonus: true,
      bonusPercent: true,
      bonusInputMode: true,
      title: true,
      justification: true,
      notes: true,
      cartaEquityGrants: true,
      suggestedRaiseNote: true,
      suggestedBonusNote: true,
      awardLetterStatus: true,
      suppressAwardLetter: true,
      awardLetterPdf: true,
      suggestedRaiseInputMode: true,
      suggestedRaisePercent: true,
      ownerId: true,
      eligibleForEquity: true,
      eligibleForPromotion: true,
      suggestedBonusInputMode: true,
      suggestedBonusPercent: true,
      oteIncrease: true,
      oteIncreaseInputMode: true,
      oteIncreasePercent: true,
      companyId: true,
      rangeId: true,
      managerId: true,
      proposerBudgetId: true,
      adjustBonusTarget: true,
      bonusTargetPercent: true,
      groupAchievementRating: true,
      individualAchievementRating: true,
      createdAt: true,
      updatedAt: true,
      bonusTargetNominal: true,
      bonusTargetInputMode: true,
      suggestedBonusTarget: true,
      suggestedBonusTargetNominal: true,
      suggestedBonusTargetPercent: true,
      eligibleForBonus: true,
      eligibleForSalary: true,
      payComponentRatios: true,
      lockPayComponentRatios: true,
      customFieldValues: true,
      logicSuggestedValues: true,
      awardLetterSentAt: true,
      grants: true,
      outlierData: true,
      company: true,
      managerLiveRecord: true,
      owner: true,
      proposerBudget: true,
      proposer: true,
      range: true,
      review: true,
      preventAutoAccept: true,
      awardLetterAcknowledgedAt: true,
    },
  },
  ProposerBudget: {
    objectName: "ProposerBudget",
    relations: {
      approvals: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "id",
        targetIdKey: "proposerBudgetId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      proposals: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "proposerBudgetId",
      },
      proposer: {
        targetObjectName: "User",
        sourceIdKey: "proposerId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      reviewId: true,
      proposerId: true,
      raiseBudget: true,
      bonusBudget: true,
      submittedAt: true,
      raiseBudgetInputMode: true,
      raiseBudgetPercent: true,
      bonusBudgetInputMode: true,
      bonusBudgetPercent: true,
      isSubmitted: true,
      equityBudget: true,
      id: true,
      companyId: true,
      createdAt: true,
      updatedAt: true,
      approvals: true,
      proposals: true,
      computedAggregates: true,
      company: true,
      proposer: true,
      review: true,
    },
  },
  Range: {
    objectName: "Range",
    relations: {
      accessExtensions: {
        hasMany: true,
        targetObjectName: "AccessExtension",
        sourceIdKey: "id",
        targetIdKey: "rangeId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      job: { targetObjectName: "Job", sourceIdKey: "jobId", targetIdKey: "id" },
      proposals: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "rangeId",
      },
      users: {
        hasMany: true,
        targetObjectName: "User",
        sourceIdKey: "id",
        targetIdKey: "rangeId",
      },
      versionBaseRecord: {
        targetObjectName: "Range",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Range",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
      zone: {
        targetObjectName: "Zone",
        sourceIdKey: "zoneId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      legacyZone: true,
      industry: true,
      code: true,
      title: true,
      legacyFamily: true,
      legacyTrack: true,
      legacyLevel: true,
      salaryCurrencyCode: true,
      baseSalary: true,
      totalPay: true,
      equity: true,
      equityMetadata: true,
      salaryIncomeFrequency: true,
      zoneId: true,
      extra: true,
      jobId: true,
      grantUnit: true,
      hireGrantDisplayName: true,
      promoGrantDisplayName: true,
      refreshGrantDisplayName: true,
      vestingCliff: true,
      vestingPeriod: true,
      vestingSchedule: true,
      tag: true,
      payPoints: true,
      versionBaseRecordId: true,
      metadata: true,
      snapshotCycleId: true,
      customFieldValues: true,
      includeBonusTargetInCompaRatioCalculation: true,
      accessExtensions: true,
      proposals: true,
      company: true,
      job: true,
      versionBaseRecord: true,
      versionHistory: true,
      zone: true,
      users: true,
      createdAt: true,
      updatedAt: true,
    },
  },
  RangeUpload: {
    objectName: "RangeUpload",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      status: true,
      rangeDimensions: true,
      payPointSettings: true,
      rangeDimensionCompletedAt: true,
      payPointsCompletedAt: true,
      employeeMappingCompletedAt: true,
      rangeUploadCompletedAt: true,
      rangeValidationCompletedAt: true,
      rangeSaveCompletedAt: true,
      createdAt: true,
      updatedAt: true,
      company: true,
    },
  },
  RangesWithTaxonomy: {
    objectName: "RangesWithTaxonomy",
    relations: {},
    hasField: {
      id: true,
      rangeCompanyId: true,
      rangeId: true,
      jobId: true,
      jobName: true,
      levelId: true,
      levelName: true,
      levelSeniority: true,
      levelSeniorityPlusOne: true,
      familyId: true,
      familyName: true,
      zoneId: true,
      zoneName: true,
      trackId: true,
      trackName: true,
    },
  },
  Review: {
    objectName: "Review",
    relations: {
      AwardLetterTemplate: { targetObjectName: "AwardLetterTemplate" },
      adminAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "adminAccessProfileId",
        targetIdKey: "id",
      },
      approvalLevels: {
        hasMany: true,
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      approvals: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      approverAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "approverAccessProfileId",
        targetIdKey: "id",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      createdBy: {
        targetObjectName: "User",
        sourceIdKey: "createdById",
        targetIdKey: "id",
      },
      endedBy: {
        targetObjectName: "User",
        sourceIdKey: "endedById",
        targetIdKey: "id",
      },
      fieldTablePlacements: {
        hasMany: true,
        targetObjectName: "FieldTablePlacement",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      logicGraphs: {
        hasMany: true,
        targetObjectName: "LogicGraph",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      partnerAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "partnerAccessProfileId",
        targetIdKey: "id",
      },
      performanceQuestions: {
        hasMany: true,
        targetObjectName: "PerformanceQuestion",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      proposals: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      proposerAccessProfile: {
        targetObjectName: "AccessProfile",
        sourceIdKey: "proposerAccessProfileId",
        targetIdKey: "id",
      },
      proposerBudgets: {
        hasMany: true,
        targetObjectName: "ProposerBudget",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
      setupConfig: {
        targetObjectName: "ReviewSetupConfig",
        sourceIdKey: "setupConfigId",
        targetIdKey: "id",
      },
      summaryFileUpload: {
        targetObjectName: "FileUpload",
        sourceIdKey: "summaryFileUploadId",
        targetIdKey: "id",
      },
      userTableState: {
        hasMany: true,
        targetObjectName: "UserTableState",
        sourceIdKey: "id",
        targetIdKey: "reviewId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      createdById: true,
      endedById: true,
      name: true,
      status: true,
      createdAt: true,
      cycleStartEmailsSentAt: true,
      cycleEndEmailsSentAt: true,
      startedAt: true,
      endedAt: true,
      exportedAt: true,
      salaryBenchmarkType: true,
      enablePromotions: true,
      enableSalary: true,
      enableBonus: true,
      enableEquity: true,
      defaultInputMode: true,
      awardLetterPostface: true,
      awardLetterPreface: true,
      salaryIncreaseType: true,
      awardLettersReleased: true,
      metadataItems: true,
      defaultCurrency: true,
      exchangeRates: true,
      exchangeRateDate: true,
      currentValuePerShare: true,
      showEquityAsMonetaryValueInAwardLetters: true,
      showEquityMonetaryValue: true,
      managersCanViewAwardLetters: true,
      awardLettersZipFileUrl: true,
      awardLettersZipFileGeneratedAt: true,
      summaryFileUploadId: true,
      updatedAt: true,
      computedBonusMode: true,
      adminAccessProfileId: true,
      approverAccessProfileId: true,
      partnerAccessProfileId: true,
      proposerAccessProfileId: true,
      setupConfigId: true,
      enabledSuggestionComponents: true,
      autoFillSettings: true,
      allowBonusTargetTypeChange: true,
      enableBonusTarget: true,
      fileOnlyMode: true,
      selectedPerformanceCycles: true,
      changeEffectiveDate: true,
      enableEventNotificationEmails: true,
      requireNotesForPromotions: true,
      requireNotesForPromotionsConfig: true,
      requireNotesForSalaryChangeAboveSuggestion: true,
      userSnapshotDate: true,
      estimatedStartDate: true,
      enablePayFrequencyChange: true,
      AwardLetterTemplate: true,
      approvalLevels: true,
      approvals: true,
      fieldTablePlacements: true,
      proposals: true,
      proposerBudgets: true,
      createdBy: true,
      endedBy: true,
      adminAccessProfile: true,
      approverAccessProfile: true,
      company: true,
      partnerAccessProfile: true,
      proposerAccessProfile: true,
      summaryFileUpload: true,
      setupConfig: true,
      userTableState: true,
      eligibleTeam: true,
      logicGraphs: true,
      performanceQuestions: true,
      grantTypeIds: true,
      salaryBudget: true,
      equityBudget: true,
      bonusBudget: true,
      computedAggregates: true,
      awardLetterAckSettings: true,
      snapshottingStartedAt: true,
      snapshottingEndedAt: true,
      snapshottingFailedAt: true,
    },
  },
  ReviewSetupConfig: {
    objectName: "ReviewSetupConfig",
    relations: {
      Review: { targetObjectName: "Review" },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      companyId: true,
      basicLogicForm: true,
      basicLogicFormInitialValuesSavedAt: true,
      ineligibilityRules: true,
      ineligibilityRulesAppliedAt: true,
      assignmentRulesAppliedAt: true,
      assignmentRules: true,
      overviewForm: true,
      budgetSettingsSavedAt: true,
      suggestionsSettingsSavedAt: true,
      approvals: true,
      usePerformanceRatings: true,
      usePerformanceQuestion: true,
      createdAt: true,
      updatedAt: true,
      overviewSettingsConfirmedAt: true,
      viewsSettingsConfirmedAt: true,
      awardLetterViewsSettingsConfirmedAt: true,
      awardLetterTemplateSettingsConfirmedAt: true,
      company: true,
      Review: true,
    },
  },
  ReviewTableFilterState: {
    objectName: "ReviewTableFilterState",
    relations: {
      Company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      userId: true,
      reviewId: true,
      companyId: true,
      filterState: true,
      createdAt: true,
      updatedAt: true,
      proposerBudgetId: true,
      Company: true,
    },
  },
  StockPrice: {
    objectName: "StockPrice",
    relations: {},
    hasField: {
      explicitKind: true,
      id: true,
      exchange: true,
      symbol: true,
      currency: true,
      date: true,
      priceCents: true,
      createdAt: true,
      updatedAt: true,
    },
  },
  SystemFieldOverride: {
    objectName: "SystemFieldOverride",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      fieldId: true,
      companyId: true,
      label: true,
      description: true,
      createdAt: true,
      updatedAt: true,
      company: true,
    },
  },
  TableSettings: {
    objectName: "TableSettings",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      tableName: true,
      companyId: true,
      config: true,
      company: true,
      updatedAt: true,
      createdAt: true,
    },
  },
  Track: {
    objectName: "Track",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      levels: {
        hasMany: true,
        targetObjectName: "Level",
        sourceIdKey: "id",
        targetIdKey: "trackId",
      },
      versionBaseRecord: {
        targetObjectName: "Track",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Track",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      name: true,
      id: true,
      companyId: true,
      extra: true,
      description: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      levels: true,
      company: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
  User: {
    objectName: "User",
    relations: {
      AccessProfileMemberRelationship: {
        hasMany: true,
        targetObjectName: "AccessProfileMemberRelationship",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      BenchmarkResult: {
        hasMany: true,
        targetObjectName: "BenchmarkResult",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      LlmConversation: {
        hasMany: true,
        targetObjectName: "LlmConversation",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      LlmConversationMessage: {
        hasMany: true,
        targetObjectName: "LlmConversationMessage",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      Presence: {
        hasMany: true,
        targetObjectName: "Presence",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      accessExtensionCreated: {
        hasMany: true,
        targetObjectName: "AccessExtension",
        sourceIdKey: "id",
        targetIdKey: "createdById",
      },
      accessExtensions: {
        hasMany: true,
        targetObjectName: "AccessExtension",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      approvalLevels: {
        hasMany: true,
        targetObjectName: "CompensationApprovalLevel",
        sourceIdKey: "id",
        targetIdKey: "approverId",
      },
      benefitAssignments: {
        hasMany: true,
        targetObjectName: "BenefitAssignment",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      compensationEvents: {
        hasMany: true,
        targetObjectName: "CompensationEvent",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      directApprovals: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "id",
        targetIdKey: "approverId",
      },
      emails: {
        hasMany: true,
        targetObjectName: "EmailAddress",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      fileUploads: {
        hasMany: true,
        targetObjectName: "FileUpload",
        sourceIdKey: "id",
        targetIdKey: "uploadedById",
      },
      grants: {
        hasMany: true,
        targetObjectName: "CartaEquityGrant",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      keyResults: {
        hasMany: true,
        targetObjectName: "KeyResult",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      managedPartner: {
        targetObjectName: "Partner",
        sourceIdKey: "governedPartnerId",
        targetIdKey: "id",
      },
      manager: {
        targetObjectName: "User",
        sourceIdKey: "managerId",
        targetIdKey: "id",
      },
      managerApprovals: {
        hasMany: true,
        targetObjectName: "CompensationApproval",
        sourceIdKey: "id",
        targetIdKey: "proposerId",
      },
      managerLiveRecordFor: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "managerId",
      },
      objectives: {
        hasMany: true,
        targetObjectName: "Objective",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      performanceAnswers: {
        hasMany: true,
        targetObjectName: "PerformanceAnswer",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      performanceRatings: {
        hasMany: true,
        targetObjectName: "PerformanceRating",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      proposals: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "ownerId",
      },
      proposerBudgets: {
        hasMany: true,
        targetObjectName: "ProposerBudget",
        sourceIdKey: "id",
        targetIdKey: "proposerId",
      },
      proposerProposals: {
        hasMany: true,
        targetObjectName: "Proposal",
        sourceIdKey: "id",
        targetIdKey: "proposerId",
      },
      range: {
        targetObjectName: "Range",
        sourceIdKey: "rangeId",
        targetIdKey: "id",
      },
      review_Review_createdByIdToUser: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "createdById",
      },
      review_Review_endedByIdToUser: {
        hasMany: true,
        targetObjectName: "Review",
        sourceIdKey: "id",
        targetIdKey: "endedById",
      },
      tableState: {
        hasMany: true,
        targetObjectName: "UserTableState",
        sourceIdKey: "id",
        targetIdKey: "userId",
      },
      team: {
        hasMany: true,
        targetObjectName: "User",
        sourceIdKey: "id",
        targetIdKey: "managerId",
      },
      versionBaseRecord: {
        targetObjectName: "User",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "User",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      id: true,
      googleId: true,
      companyId: true,
      securePassword: true,
      name: true,
      role: true,
      superUserRole: true,
      governedPartnerId: true,
      isNonEmployee: true,
      worksForComprehensive: true,
      lastLogin: true,
      lastActive: true,
      avatarUrl: true,
      status: true,
      intercomSessionHash: true,
      integrationUserId: true,
      paginationPageSize: true,
      uiCurrency: true,
      privacyPolicyConsentDate: true,
      legalName: true,
      legacyCompanyId: true,
      legacyUserId: true,
      legacyManagerId: true,
      managerId: true,
      legacyFinchId: true,
      legacyName: true,
      title: true,
      department: true,
      employmentType: true,
      employmentSubType: true,
      startDate: true,
      endDate: true,
      salary: true,
      salaryEffectiveDate: true,
      salaryIncomeFrequency: true,
      salaryCurrencyCode: true,
      shares: true,
      zone: true,
      industry: true,
      bonusTargetPercent: true,
      variableTarget: true,
      performanceRating: true,
      priorPerformanceRating: true,
      salaryNote: true,
      race: true,
      gender: true,
      metadata: true,
      employeeId: true,
      city: true,
      country: true,
      state: true,
      legacyLegalName: true,
      lastGrantDate: true,
      rangeId: true,
      isTerminated: true,
      extra: true,
      fieldSources: true,
      oktaId: true,
      versionBaseRecordId: true,
      bonusTargetNominal: true,
      bonusTargetInputMode: true,
      snapshotCycleId: true,
      customFieldValues: true,
      createdAt: true,
      updatedAt: true,
      enableCycleEventEmail: true,
      benchmark: true,
      accessExtensionCreated: true,
      accessExtensions: true,
      AccessProfileMemberRelationship: true,
      benefitAssignments: true,
      grants: true,
      approvalLevels: true,
      managerApprovals: true,
      directApprovals: true,
      compensationEvents: true,
      emails: true,
      performanceRatings: true,
      managerLiveRecordFor: true,
      proposals: true,
      proposerProposals: true,
      proposerBudgets: true,
      review_Review_createdByIdToUser: true,
      review_Review_endedByIdToUser: true,
      tableState: true,
      company: true,
      manager: true,
      team: true,
      range: true,
      versionBaseRecord: true,
      versionHistory: true,
      hrSupportProviders: true,
      hrSupportRecipients: true,
      reviews: true,
      objectives: true,
      keyResults: true,
      Presence: true,
      performanceAnswers: true,
      LlmConversation: true,
      LlmConversationMessage: true,
      fileUploads: true,
      managedPartner: true,
      BenchmarkResult: true,
    },
  },
  UserTableState: {
    objectName: "UserTableState",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      review: {
        targetObjectName: "Review",
        sourceIdKey: "reviewId",
        targetIdKey: "id",
      },
      user: {
        targetObjectName: "User",
        sourceIdKey: "userId",
        targetIdKey: "id",
      },
    },
    hasField: {
      explicitKind: true,
      userId: true,
      reviewId: true,
      config: true,
      id: true,
      companyId: true,
      createdAt: true,
      updatedAt: true,
      company: true,
      review: true,
      user: true,
    },
  },
  Zone: {
    objectName: "Zone",
    relations: {
      company: {
        targetObjectName: "Company",
        sourceIdKey: "companyId",
        targetIdKey: "id",
      },
      ranges: {
        hasMany: true,
        targetObjectName: "Range",
        sourceIdKey: "id",
        targetIdKey: "zoneId",
      },
      versionBaseRecord: {
        targetObjectName: "Zone",
        sourceIdKey: "versionBaseRecordId",
        targetIdKey: "id",
      },
      versionHistory: {
        hasMany: true,
        targetObjectName: "Zone",
        sourceIdKey: "id",
        targetIdKey: "versionBaseRecordId",
      },
    },
    hasField: {
      explicitKind: true,
      name: true,
      id: true,
      geofactor: true,
      companyId: true,
      extra: true,
      description: true,
      versionBaseRecordId: true,
      snapshotCycleId: true,
      ranges: true,
      company: true,
      versionBaseRecord: true,
      versionHistory: true,
    },
  },
} as const;
