import { pascalCase } from "lib/string";
import { Prisma } from "prisma/cm/client";
import { ModelManifest } from "prisma/cm/outputFromGenerators/posStateGraphConfig/pos-state-graph-config";

export const ObjectPoolModelName = Prisma.ModelName;
export type ObjectPoolModelName = Prisma.ModelName;

export type ObjectPoolModel<TModelName extends ObjectPoolModelName> = ModelManifest[TModelName];

export type ObjectPoolModelUpdateArgs<TModelName extends ObjectPoolModelName> = Partial<
  ObjectPoolModel<TModelName>
>;

export type ObjectPoolModelFields<TModelName extends ObjectPoolModelName> =
  keyof ObjectPoolModel<TModelName>;

type F = ObjectPoolModelFields<"EmailAddress">;

export type ObjectPoolModelId<TModelName extends ObjectPoolModelName> =
  ObjectPoolModel<TModelName>["id"];

export type ObjectPoolModelBatchUpdateArgs<TModelName extends ObjectPoolModelName> = Array<{
  where: {
    id: ObjectPoolModelId<TModelName>;
  };
  data: Partial<ObjectPoolModel<TModelName>>;
}>;

export type ObjectPoolEntityMap<TModelName extends ObjectPoolModelName> = {
  modelName: TModelName;
  ids: Array<ObjectPoolModelId<TModelName>>;
  entities: Record<ObjectPoolModelId<TModelName>, ObjectPoolModel<TModelName>>;
  indexes: {
    [s in keyof ObjectPoolModel<TModelName>]?: {
      [v: string | number]: Array<ObjectPoolModelId<TModelName>>;
    };
  };
  isReady: boolean;
};

export type ObjectPoolEntityMapManifest = {
  [TModelName in ObjectPoolModelName]: ObjectPoolEntityMap<TModelName>;
};

// bit of a hack since this is a "model" used by object pool
const PseudoModelNames = ["FieldTextSetting"];

function isModelName(modelName: string): modelName is ObjectPoolModelName {
  if (modelName in ObjectPoolModelName || PseudoModelNames.includes(modelName)) {
    return true;
  }

  return false;
}

export function toModelName(modelName: string): ObjectPoolModelName {
  const pascalCased = pascalCase(modelName);
  if (isModelName(pascalCased)) {
    return pascalCased;
  }
  throw new Error(`${pascalCased} is not a valid model name`);
}
