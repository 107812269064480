import { WithOptional } from "@hen/stdlib/types";
import dayjs from "dayjs";
import _ from "lodash";
import { QueryClient, useQueryClient } from "react-query";

import { OpTransactionType, OpTransaction } from "lib/shared/object-pool";
import { User } from "prisma/cm/client";
import { useAuthenticatedUserFromReduxStore } from "services/user";
import {
  ObjectPoolEntityMapManifest,
  ObjectPoolModel,
  ObjectPoolModelBatchUpdateArgs,
  ObjectPoolModelId,
  ObjectPoolModelName,
  ObjectPoolModelUpdateArgs,
} from "utils/types";

import { ObjectPoolEntityMap } from "./useGetObjectPoolModelQuery";

type BoundQueryClient = {
  queryClient: QueryClient;
  companyId: NonNullable<User["companyId"]>;
};

/**
 * This is bad. We're currently reliant on the companyId in order to grab any of this
 * data from the query cache - VERY leaky abstraction. At the very least in the near
 * term, we won't rely on companyId since we'll be using the TRPC setup differently.
 *
 * In the meantime, we get contain the leak with this helper. We can remove it in
 * the future in favor of simply calling `useQueryClient()` when the companyId
 * isn't required.
 */
export function useBoundQueryClient(): BoundQueryClient {
  const queryClient = useQueryClient();
  // We can assume this is non-null
  const user = useAuthenticatedUserFromReduxStore() as NonNullable<
    ReturnType<typeof useAuthenticatedUserFromReduxStore>
  >;
  const companyId = user?.companyId;
  return { queryClient, companyId };
}

export const objectPoolEntityMapManifestQueryKey = "objects";

export function getEntityMapManifestFromQueryCache(boundQueryClient: BoundQueryClient) {
  const { queryClient } = boundQueryClient;
  const entityMapManifest = queryClient.getQueryData(objectPoolEntityMapManifestQueryKey) || {};
  return entityMapManifest as ObjectPoolEntityMapManifest;
}

export function getEntityMapFromQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
) {
  const entityMapManifest = getEntityMapManifestFromQueryCache(boundQueryClient);
  const entityMap = entityMapManifest[modelName];
  return entityMap;
}

export function getRecordFromQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  id: ObjectPoolModelId<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  return entityMap?.entities[id];
}

export function replaceEntityMapManifestInQueryCache(
  boundQueryClient: BoundQueryClient,
  newEntityMapManifest: ObjectPoolEntityMapManifest,
) {
  const { queryClient } = boundQueryClient;
  queryClient.setQueryData(objectPoolEntityMapManifestQueryKey, newEntityMapManifest);
}

export function mergeEntityMapManifestInQueryCache(
  boundQueryClient: BoundQueryClient,
  newEntityMapManifest: Partial<ObjectPoolEntityMapManifest>,
) {
  const manifest = getEntityMapManifestFromQueryCache(boundQueryClient);
  // Note shallow merge without any clone, since clone / deep merge here would be... expensive.
  // TODO; We may have to clone here? Unclear.
  _.extend(manifest, newEntityMapManifest);
  replaceEntityMapManifestInQueryCache(boundQueryClient, manifest);
}

export function updateEntityMapReadyInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  isReady: boolean,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const newEntityMap = {
    ...entityMap,
    isReady,
  };
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function replaceEntityMapInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  newEntityMap: ObjectPoolEntityMap<TModelName>,
) {
  const entityMaps = getEntityMapManifestFromQueryCache(boundQueryClient);

  // VERY LEAKY, but if `isReady` is undefined we can assume that the
  // data comes from the server and is therefore ready.
  if (_.isUndefined(newEntityMap.isReady)) {
    newEntityMap.isReady = true;
  }

  // Note that previously here we mutated the entity map rather than
  // the below shallow merge, but that did not result in the desired
  // reactivity, so for now we do this. It's likely to create an added
  // perf hit which we should be able to mitigate by moving everything
  // into redux and letting redux selectors manage the change detection.
  const newEntityMaps = {
    ...entityMaps,
    [modelName]: newEntityMap,
  };
  const { queryClient } = boundQueryClient;
  queryClient.setQueryData(objectPoolEntityMapManifestQueryKey, newEntityMaps);
  return newEntityMap;
}

export function replaceEntityInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  newEntity: ObjectPoolEntityMap<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache(boundQueryClient, modelName);
  const newEntityMap = {
    ...entityMap,
    entities: {
      ...(entityMap?.entities ?? {}),
      // @ts-expect-error TS2339
      [newEntity.id]: newEntity,
    },
  };
  return replaceEntityMapInQueryCache<TModelName>(boundQueryClient, modelName, newEntityMap);
}

export function batchUpdateRecordsInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  params: ObjectPoolModelBatchUpdateArgs<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache(boundQueryClient, modelName);
  const entityMapClone = _.merge({}, entityMap);
  const newEntityMap = params.reduce((entityMapAcc, { where: { id }, data }) => {
    const record = entityMapAcc.entities[id];
    const newRecord = {
      ...record,
      ...data,
    };
    entityMapAcc.entities[id] = newRecord;
    return entityMapAcc;
  }, entityMapClone);
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function updateRecordInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  id: ObjectPoolModelId<TModelName>,
  updateArgs: ObjectPoolModelUpdateArgs<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const oldRecord = entityMap?.entities[id];
  // We may wish for updates to be a little more nuanced,
  // but for now we simply merge.
  const newRecord = _.merge({}, oldRecord, updateArgs);
  // @ts-expect-error TS2345
  return replaceEntityInQueryCache(boundQueryClient, modelName, newRecord);
}

export function updateRecordsInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  ids: Array<ObjectPoolModelId<TModelName>>,
  updateFn: (prevValue: ObjectPoolModel<TModelName>) => Partial<ObjectPoolModel<TModelName>>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const newEntityMap = _.merge({}, entityMap);
  ids.forEach((id) => {
    const record = entityMap?.entities[id];
    const newRecord = {
      ...record,
      ...updateFn(record),
    };
    newEntityMap.entities[id] = newRecord;
  });
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function replaceEntitiesInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  newPartialEntityMap: ObjectPoolEntityMap<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const newEntities = _.merge({}, entityMap?.entities, newPartialEntityMap.entities);
  const newEntityMap = {
    ...entityMap,
    entities: newEntities,
  };
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function replaceRecordInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  _id: ObjectPoolModelId<TModelName>,
  newRecord: ObjectPoolModel<TModelName>,
) {
  // @ts-expect-error TS2345
  return replaceEntityInQueryCache(boundQueryClient, modelName, newRecord);
}

function getUpdatedEntityMapIndexes<TModelName extends ObjectPoolModelName>(
  _modelName: TModelName,
  entityMap: ObjectPoolEntityMap<TModelName>,
  newRecord: ObjectPoolModel<TModelName>,
) {
  const existingIndexes = entityMap.indexes;
  if (!existingIndexes) return existingIndexes;

  const baseNewIndexes = _.merge({}, existingIndexes);
  const newIndexes = Object.keys(entityMap.indexes).reduce((acc, indexedFieldName) => {
    const value = newRecord[indexedFieldName as keyof typeof newRecord];
    if (!value) return acc;
    // @ts-expect-error TS7053
    if (!acc[indexedFieldName][value]) acc[indexedFieldName][value] = [];
    const newRecordId = (newRecord as typeof newRecord & { id: string | null }).id;
    if (!newRecordId) throw Error("Need an id");
    // @ts-expect-error TS7053
    acc[indexedFieldName][value].push(newRecordId);
    return acc;
  }, baseNewIndexes);
  return newIndexes;
}

export function addRecordToEntityMap<TModelName extends ObjectPoolModelName>(
  entityMap: ObjectPoolEntityMap<TModelName>,
  modelName: TModelName,
  newRecord: ObjectPoolModel<TModelName>,
): ObjectPoolEntityMap<TModelName> {
  const newRecordId = (newRecord as typeof newRecord & { id: string | null }).id;
  if (!newRecordId) throw Error("Need an id");

  const newEntityMap: ObjectPoolEntityMap<TModelName> = {
    ids: (entityMap?.ids ?? []).concat(newRecordId),
    entities: {
      ...entityMap?.entities,
      [newRecordId]: newRecord,
    },
    isReady: true,
    indexes: getUpdatedEntityMapIndexes(modelName, entityMap, newRecord),
  };
  return newEntityMap;
}

export function addRecordToQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  newRecord: ObjectPoolModel<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const newEntityMap = addRecordToEntityMap(entityMap, modelName, newRecord);
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function addRecordsToQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  newRecords: ObjectPoolModel<TModelName>[],
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const newEntityMap = newRecords.reduce((acc, model) => {
    const modelId = (model as typeof model & { id: string | number }).id;
    if (!modelId) throw new Error("Need an id");
    return {
      ...entityMap,
      ids: acc.ids.concat(modelId),
      entities: {
        ...acc.entities,
        [modelId]: model,
      },
      isReady: true,
      indexes: getUpdatedEntityMapIndexes(modelName, entityMap, model),
    };
  }, entityMap);
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function deleteRecordsFromQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  ids: Array<ObjectPoolModelId<TModelName>>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const newEntityMap = entityMap.ids.reduce(
    (acc, id) => {
      if (ids.includes(id)) return acc;
      acc.ids = [...acc.ids, id];
      acc.entities[id] = entityMap.entities[id];
      return acc;
    },
    {
      ids: [],
      entities: {},
      isReady: true,
      indexes: {},
    } as ObjectPoolEntityMap<TModelName>,
  );
  newEntityMap.indexes = ids.reduce((acc, idToRemove) => {
    return removeIdFromIndexes(
      modelName,
      { indexes: acc } as ObjectPoolEntityMap<TModelName>,
      idToRemove,
    );
  }, entityMap.indexes);
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function deleteRecordFromQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  id: ObjectPoolModelId<TModelName>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  // @ts-expect-error TS2339
  const { id: _entityToRemove, ...remainingEntities } = entityMap.entities;
  const newEntityMap = {
    ids: entityMap.ids.filter((idEntry) => idEntry !== id),
    entities: remainingEntities,
  };
  // @ts-expect-error TS2345
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

type WithOptionalId<TObj extends { id: unknown }> = WithOptional<TObj, "id">;

function generateGetUniqueIdForUpsert<TModelName extends ObjectPoolModelName>(
  modelName: TModelName,
): (r: WithOptionalId<ObjectPoolModel<TModelName>>) => ObjectPoolModelId<TModelName> {
  switch (modelName) {
    case "AccessExtension": {
      return (record: Omit<ObjectPoolModel<TModelName>, "id">) => {
        // Really wish we didn't have to assert here but it'll do for now.
        const typedRecord = record as unknown as ObjectPoolModel<"AccessExtension">;
        return `${typedRecord.userId}_${typedRecord.rangeId}`;
      };
    }
    case "ReviewTableFilterState": {
      return (record: Omit<ObjectPoolModel<TModelName>, "id">) => {
        // Really wish we didn't have to assert here but it'll do for now.
        const typedRecord = record as unknown as ObjectPoolModel<"ReviewTableFilterState">;
        return `${typedRecord.userId}_${typedRecord.proposerBudgetId}`;
      };
    }
    case "UserTableState": {
      return (record: Omit<ObjectPoolModel<TModelName>, "id">) => {
        // Really wish we didn't have to assert here but it'll do for now.
        const typedRecord = record as unknown as ObjectPoolModel<"UserTableState">;
        return `${typedRecord.userId}_${typedRecord.reviewId}`;
      };
    }
    // @ts-expect-error TS2678
    case "FieldTextSetting": {
      return (record: Omit<ObjectPoolModel<TModelName>, "id">) => {
        // Really wish we didn't have to assert here but it'll do for now.
        const typedRecord = record as unknown as ObjectPoolModel<"ReviewTableFilterState">;
        // @ts-expect-error TS2339
        return `${typedRecord.fieldId}`;
      };
    }
    default: {
      throw Error(`Upsert not yet supported for model: "${modelName}"`);
    }
  }
}

export function upsertRecordsInQueryCache<TModelName extends ObjectPoolModelName>(
  boundQueryClient: BoundQueryClient,
  modelName: TModelName,
  data: Array<WithOptionalId<ObjectPoolModel<TModelName>>>,
) {
  const entityMap = getEntityMapFromQueryCache<TModelName>(boundQueryClient, modelName);
  const getUniqueIdForUpsert = generateGetUniqueIdForUpsert(modelName);
  const uniqueRecordDict = _.mapKeys(entityMap.entities, (record) =>
    getUniqueIdForUpsert(_.omit(record, "id")),
  );

  // @ts-expect-error TS2769
  const newEntityMap = data.reduce((acc, upsertDataEntry) => {
    const uniqueRecordId = getUniqueIdForUpsert(upsertDataEntry);
    const existingRecord = uniqueRecordDict[uniqueRecordId];
    if (!existingRecord) {
      const newRecord = {
        ...upsertDataEntry,
        id: uniqueRecordId,
        // TODO: Wish we didn't have to assert here but it'll do for now.
      } as ObjectPoolModel<TModelName>;
      return {
        ids: [...acc.ids, uniqueRecordId],
        entities: {
          ...acc.entities,
          [uniqueRecordId]: newRecord,
        },
      };
    }

    const updatedRecord = {
      ...existingRecord,
      ...upsertDataEntry,
      id: existingRecord.id,
    };

    return {
      ids: acc.ids,
      entities: {
        ...acc.entities,
        [existingRecord.id]: updatedRecord,
      },
    };
  }, entityMap);

  // @ts-expect-error TS2345
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

function removeIdFromIndexes<TModelName extends ObjectPoolModelName>(
  _modelName: TModelName,
  entityMap: ObjectPoolEntityMap<TModelName>,
  idToRemove: ObjectPoolModelId<TModelName>,
) {
  const existingIndexes = entityMap.indexes;
  if (!existingIndexes) return existingIndexes;

  const newIndexes = _.mapValues(existingIndexes, (index) => {
    if (index) {
      return _.mapValues(index, (ids) => ids.filter((id: string | number) => id !== idToRemove));
    }
  });

  return newIndexes;
}

function resolveDeleteOneTransaction<TModelName extends ObjectPoolModelName>(
  entityMap: ObjectPoolEntityMap<TModelName>,
  transaction: OpTransaction<TModelName, "DELETE">,
): ObjectPoolEntityMap<TModelName> {
  const { id } = transaction.payload.where;
  const newEntities = _.pickBy(entityMap.entities, (_val, key) => id !== key);
  const newIds = entityMap.ids.filter((entryId) => entryId !== id);
  const newIndexes = removeIdFromIndexes(transaction.payload.modelName, entityMap, id);
  return { entities: newEntities, ids: newIds, indexes: newIndexes, isReady: true };
}

function resolveDeleteManyTransaction<TModelName extends ObjectPoolModelName>(
  entityMap: ObjectPoolEntityMap<TModelName>,
  transaction: OpTransaction<TModelName, "DELETE_MANY">,
): ObjectPoolEntityMap<TModelName> {
  const ids = transaction.payload.where.id.in;
  const newEntities = _.pickBy(
    entityMap.entities,
    (_val, key) =>
      !ids.includes(
        // @ts-expect-error TS2345
        key,
      ),
  );
  const newIds = _.difference(entityMap.ids, ids);
  const newIndexes = ids.reduce((acc, idToRemove) => {
    return removeIdFromIndexes(
      transaction.payload.modelName,
      { indexes: acc } as ObjectPoolEntityMap<TModelName>,
      idToRemove,
    );
  }, entityMap.indexes);
  return { entities: newEntities, ids: newIds, indexes: newIndexes, isReady: true };
}

function resolveUpdateOneTransaction<TModelName extends ObjectPoolModelName>(
  entityMap: ObjectPoolEntityMap<TModelName>,
  transaction: OpTransaction<TModelName, "UPDATE">,
): ObjectPoolEntityMap<TModelName> {
  const { id } = transaction.payload.where;
  const entity = entityMap.entities[id];

  // If the entity has been updated since the transaction was created, we don't want to update it.
  if (
    // @ts-expect-error TS2339
    entity?.updatedAt &&
    // @ts-expect-error TS2339
    transaction.payload.data?.updatedAt &&
    // @ts-expect-error TS2339
    dayjs(entity.updatedAt).isAfter(dayjs(transaction.payload.data.updatedAt))
  ) {
    return entityMap;
  }

  const newEntities = {
    ...entityMap.entities,
    [id]: {
      ...entityMap.entities[id],
      ...transaction.payload.data,
    },
  };
  return {
    ...entityMap,
    // @ts-expect-error TS2322
    entities: newEntities,
  };
}

function resolveCreateOneTransaction<TModelName extends ObjectPoolModelName>(
  entityMap: ObjectPoolEntityMap<TModelName>,
  transaction: OpTransaction<TModelName, "CREATE">,
): ObjectPoolEntityMap<TModelName> {
  return addRecordToEntityMap(entityMap, transaction.payload.modelName, transaction.payload.data);
}

function resolveReplaceEntityMapTransaction<TModelName extends ObjectPoolModelName>(
  _entityMap: ObjectPoolEntityMap<TModelName>,
  transaction: OpTransaction<TModelName, "REPLACE_ENTITY_MAP">,
) {
  return transaction.payload.data;
}

function resolveTransactionForEntityMap<
  TModelName extends ObjectPoolModelName,
  TType extends OpTransactionType,
>(
  entityMap: ObjectPoolEntityMap<TModelName>,
  transaction: OpTransaction<TModelName, TType>,
): ObjectPoolEntityMap<TModelName> {
  switch (transaction.type) {
    case "CREATE":
      // @ts-expect-error TS2322
      return resolveCreateOneTransaction(entityMap, transaction);
    case "DELETE":
      // @ts-expect-error TS2322
      return resolveDeleteOneTransaction(entityMap, transaction);
    case "DELETE_MANY":
      // @ts-expect-error TS2322
      return resolveDeleteManyTransaction(entityMap, transaction);
    case "UPDATE":
      // @ts-expect-error TS2322
      return resolveUpdateOneTransaction(entityMap, transaction);
    case "REPLACE_ENTITY_MAP":
      // @ts-expect-error TS2322
      return resolveReplaceEntityMapTransaction(entityMap, transaction);
    default:
      throw Error("Unknown transaction type");
  }
}

export function resolveObjectPoolTransactionInQueryCache(
  boundQueryClient: BoundQueryClient,
  transaction: OpTransaction,
) {
  const { modelName } = transaction.payload;
  const entityMap = getEntityMapFromQueryCache(boundQueryClient, modelName);
  const newEntityMap = resolveTransactionForEntityMap(entityMap, transaction);
  return replaceEntityMapInQueryCache(boundQueryClient, modelName, newEntityMap);
}

export function resolveObjectPoolTransactionsInQueryCache(
  boundQueryClient: BoundQueryClient,
  transactions: Array<OpTransaction>,
) {
  const entityMaps = getEntityMapManifestFromQueryCache(boundQueryClient);
  const newEntityMaps = transactions.reduce((acc, transaction) => {
    const { modelName } = transaction.payload;
    const entityMap = acc[modelName];
    // @ts-expect-error TS2322
    acc[modelName] = resolveTransactionForEntityMap(entityMap, transaction);
    return acc;
  }, entityMaps);
  return replaceEntityMapManifestInQueryCache(boundQueryClient, newEntityMaps);
}
