import { createContext, useContext, useMemo } from "react";

import { Stack, Switch } from "@mantine/core";
import { readLocalStorageValue, useLocalStorage } from "@mantine/hooks";
import { modals } from "@mantine/modals";

function createFeatureToggleHooks({ key, defaultValue }: { key: string; defaultValue: boolean }) {
  function useFeatureToggle() {
    const [enabled, setEnabled] = useLocalStorage({
      key,
      defaultValue,
    });
    const result = useMemo(() => [enabled, setEnabled] as const, [enabled, setEnabled]);
    return result;
  }
  function getFeatureEnabled() {
    return readLocalStorageValue({
      key,
      defaultValue,
    });
  }
  return [useFeatureToggle, getFeatureEnabled] as const;
}

const ENABLE_FRONTEND_PERFORMANCE_LOGGING_KEY = "enable-frontend-performance-logging";
const [useEnableFrontendPerformanceLogging, getEnableFrontendPerformanceLogging] =
  createFeatureToggleHooks({
    key: ENABLE_FRONTEND_PERFORMANCE_LOGGING_KEY,
    defaultValue: false,
  });
export { useEnableFrontendPerformanceLogging, getEnableFrontendPerformanceLogging };

const ENABLE_RANGE_MAPPING_SETUP_KEY = "enable-range-mapping-setup";
const [useEnableRangeMappingSetup, getEnableRangeMappingSetup] = createFeatureToggleHooks({
  key: ENABLE_RANGE_MAPPING_SETUP_KEY,
  defaultValue: false,
});
export { useEnableRangeMappingSetup, getEnableRangeMappingSetup };

const ENABLE_MANUAL_UPLOAD_METADATA_HOOK_KEY = "enable-manual-upload-metadata-hook";
const [useEnableManualUploadMetadataHook, getEnableManualUploadMetadataHook] =
  createFeatureToggleHooks({
    key: ENABLE_MANUAL_UPLOAD_METADATA_HOOK_KEY,
    defaultValue: false,
  });
export { useEnableManualUploadMetadataHook, getEnableManualUploadMetadataHook };

const ENABLE_IN_CYCLE_PAY_FREQUENCY_CHANGE_HOOK_KEY = "enable-in-cycle-pay-frequency-change-hook";
const [useEnableInCyclePayFrequencyChangeHook, getEnableInCyclePayFrequencyChangeHook] =
  createFeatureToggleHooks({
    key: ENABLE_IN_CYCLE_PAY_FREQUENCY_CHANGE_HOOK_KEY,
    defaultValue: false,
  });
export { useEnableInCyclePayFrequencyChangeHook, getEnableInCyclePayFrequencyChangeHook };

const ENABLE_CYCLE_HEALTH_ALERT_KEY = "enable-cycle-health-alert";
const [useEnableCycleHealthAlert, getEnableCycleHealthAlert] = createFeatureToggleHooks({
  key: ENABLE_CYCLE_HEALTH_ALERT_KEY,
  defaultValue: false,
});
export { useEnableCycleHealthAlert, getEnableCycleHealthAlert };

type FeatureToggleContextValue = {
  frontendPerfLoggingEnabled: boolean;
  rangeMappingSetupEnabled: boolean;
  manualUploadMetadataHookEnabled: boolean;
  inCyclePayFrequencyChangeEnabled: boolean;
  cycleHealthAlertEnabled: boolean;
};

const FeatureToggleContext = createContext<FeatureToggleContextValue>(
  {} as FeatureToggleContextValue,
);

export function FeatureToggleProvider({ children }: { children: React.ReactNode }) {
  const [frontendPerfLoggingEnabled] = useEnableFrontendPerformanceLogging();
  const [rangeMappingSetupEnabled] = useEnableRangeMappingSetup();
  const [manualUploadMetadataHookEnabled] = useEnableManualUploadMetadataHook();
  const [inCyclePayFrequencyChangeEnabled] = useEnableInCyclePayFrequencyChangeHook();
  const [cycleHealthAlertEnabled] = useEnableCycleHealthAlert();

  const value = useMemo(
    () => ({
      frontendPerfLoggingEnabled,
      rangeMappingSetupEnabled,
      manualUploadMetadataHookEnabled,
      inCyclePayFrequencyChangeEnabled,
      cycleHealthAlertEnabled,
    }),
    [
      frontendPerfLoggingEnabled,
      rangeMappingSetupEnabled,
      manualUploadMetadataHookEnabled,
      inCyclePayFrequencyChangeEnabled,
      cycleHealthAlertEnabled,
    ],
  );
  return <FeatureToggleContext.Provider value={value}>{children}</FeatureToggleContext.Provider>;
}

export function useFeatureToggles() {
  return useContext(FeatureToggleContext);
}

export function FeatureToggles() {
  const [frontendPerfLoggingEnabled, setFrontendPerfLoggingEnabled] =
    useEnableFrontendPerformanceLogging();
  const [rangeMappingSetupEnabled, setRangeMappingSetupEnabled] = useEnableRangeMappingSetup();
  const [manualUploadMetadataHookEnabled, setManualUploadMetadataHookEnabled] =
    useEnableManualUploadMetadataHook();

  const [inCyclePayFrequencyChangeEnabled, setInCyclePayFrequencyChangeEnabled] =
    useEnableInCyclePayFrequencyChangeHook();

  const [cycleHealthAlertEnabled, setCycleHealthAlertEnabled] = useEnableCycleHealthAlert();

  return (
    <Stack>
      <Switch
        label="Enable frontend performance logging"
        checked={frontendPerfLoggingEnabled}
        onChange={(event) => setFrontendPerfLoggingEnabled(event.target.checked)}
      />
      <Switch
        label="Enable range mapping setup"
        checked={rangeMappingSetupEnabled}
        onChange={(event) => setRangeMappingSetupEnabled(event.target.checked)}
      />
      <Switch
        label="Enable manual upload metadata hook"
        checked={manualUploadMetadataHookEnabled}
        onChange={(event) => setManualUploadMetadataHookEnabled(event.target.checked)}
      />
      <Switch
        label="Enable in-cycle pay frequency change"
        checked={inCyclePayFrequencyChangeEnabled}
        onChange={(event) => setInCyclePayFrequencyChangeEnabled(event.target.checked)}
      />
      <Switch
        label="Enable cycle health alert"
        checked={cycleHealthAlertEnabled}
        onChange={(event) => setCycleHealthAlertEnabled(event.target.checked)}
      />
    </Stack>
  );
}

export function openFeatureTogglesModal() {
  modals.open({
    title: "Feature toggles",
    children: <FeatureToggles />,
  });
}
